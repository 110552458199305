import { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import UploadFile from "./UploadFile";

class UploadModal extends Component {
    state = {
        data: {},
        source: 'linkedin',
        stage: 'initial'
    };

    hide = () => {
        this.setState({
            data: {}
        })
        this.props.hide()
    }

    render = () => {
        // console.log(this.state.data)
        return (
            <Modal show={this.props.show} onHide={this.hide}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Source</Form.Label>
                    <Form.Select value={this.state.source} onChange={(e) => this.setState({source: e.target.value})}>
                        <option value="linkedin">LinkedIn</option>
                        <option value="webinar">Webinars</option>
                        <option value="templates">Templates</option>
                    </Form.Select>
                    <Form.Label style={{marginTop: "20px"}}>Stage</Form.Label>
                    <Form.Select value={this.state.stage} onChange={(e) => this.setState({stage: e.target.value})}>
                        <option value="initial">Initial</option>
                        <option value="1st_follow_up">1st Follow Up</option>
                        <option value="2nd_follow_up">2nd Follow Up</option>
                        <option value="3rd_follow_up">3rd Follow Up</option>
                        <option value="responded">Responded</option>
                        <option value="flag">Flag</option>
                    </Form.Select>
                    <UploadFile dbData={this.props.dbData} dbDataChange={this.props.dbDataChange} source={this.state.source} stage={this.state.stage} data={this.state.data} clearData={() => this.setState({data: {}})} handleFileUpload={(data) => this.setState({data: data})} />
                    {
                        this.state.data.rows ?
                        <ul>
                            <li>Rows before filter: {this.state.data.newRows.length + this.state.data.skipped.total.length}</li>
                            <li>Rows filtered: {this.state.data.skipped.total.length}
                                <ul>
                                    <li>Basic filter: {this.state.data.skipped.emptyEmail.length + this.state.data.skipped.duplicate.length + this.state.data.skipped.regex.length + this.state.data.skipped.domainFailed.length}
                                        <ul>
                                            <li>Email empty: {this.state.data.skipped.emptyEmail.length}</li>   
                                            <li>Duplicate email: {this.state.data.skipped.duplicate.length}</li>
                                            <li>Regex failure: {this.state.data.skipped.regex.length}</li>
                                            <li>Domain (MX) failure: {this.state.data.skipped.domainFailed.length}</li>
                                        </ul>
                                    </li>
                                    <li>Enhanced filter: {this.state.data.skipped.undeliverable.length + this.state.data.skipped.unknown.length}
                                        <ul>
                                            <li>Undeliverable: {this.state.data.skipped.undeliverable.length}</li>
                                            <li>Unknown: {this.state.data.skipped.unknown.length}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Rows to upload after filter: {this.state.data.newRows.length}
                                <ul>
                                    <li>Risky: {this.state.data.skipped.risky.length}</li>
                                    <li>Deliverable: {this.state.data.skipped.deliverable.length}</li>
                                </ul>
                            </li>
                        </ul>
                        : <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" variant="secondary" onClick={this.hide}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={this.state.data.rows ? false : true} variant="primary" onClick={async () => await this.props.handleUploadingCSV(this.state.data, this.state.source, this.state.stage)}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default UploadModal;