import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Navigate } from 'react-router-dom'

import { Auth } from 'aws-amplify'

class Login extends Component {
    state = {
        email: '',
        password: ''
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state.email, this.state.password)
    }

    render = () => {
        return (
            <div className="container-fluid" style={{ marginTop: "120px", textAlign: "left" }}>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <Form>
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="email"></Form.Control>
                            <Form.Label style={{marginTop: "20px"}}>Password</Form.Label>
                            <Form.Control value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} type="password"></Form.Control>
                        </Form>
                        <Button variant="success" style={{marginTop: "20px"}} onClick={(e) => this.handleSubmit(e)}>Login</Button>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        )
    }
}

export default Login