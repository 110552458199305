import { Component } from "react";
import { Nav } from 'react-bootstrap';

class TabNav extends Component {
    render = () => {
        return (
            <Nav variant="tabs" style={{marginTop: "20px"}} defaultActiveKey="home">
                <Nav.Item>
                    <Nav.Link name="home" eventKey="home" onClick={(e) => this.props.handleTabSelection(e.target.name)}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link name="queue" eventKey="queue" onClick={(e) => this.props.handleTabSelection(e.target.name)}>Queue</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link name="templateManager" onClick={(e) => this.props.handleTabSelection(e.target.name)} eventKey="templateManager">Template Manager</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link name="reporting" onClick={(e) => this.props.handleTabSelection(e.target.name)} eventKey="reporting">Reporting</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link name="preferences" onClick={(e) => this.props.handleTabSelection(e.target.name)} eventKey="preferences">Preferences</Nav.Link>
                </Nav.Item>
            </Nav>
        )
    }
}

export default TabNav;