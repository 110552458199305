import './App.css';
import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Login from './components/Login'

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      // console.log('e', e)
      userHasAuthenticated(false)
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    navigate("/");
  }

  async function signIn(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      // console.log('user', user)
      userHasAuthenticated(true)
      navigate("/");
    } catch (error) {
      // console.log('error signing in', error);
      userHasAuthenticated(false)
    }
  }

  return (
    !isAuthenticating && (
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={isAuthenticated ? <Dashboard handleLogout={handleLogout} /> : <Login signIn={signIn} />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </div>
    )
  );
}

export default App;