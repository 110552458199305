import React, { Component } from "react";
import Papa from "papaparse";
import { Form, InputGroup } from 'react-bootstrap';
import _, { update } from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';

class UploadFile extends Component {

    state = {
        startRow: '',
        endRow: ''
    }

    handleRegexCheck = email => {
        const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(email.match(re) !== null) {
            // console.log('valid email format')
            return true
        } else {
            // console.log('not valid email format')
            return false
        }
    }

    handleFormattingUpload = e => {
        try {
            const files = e.target.files;
            if (files) {
                Papa.parse(files[0], {
                    complete: async (results, file) => {
                        if(results.data.length > 0) {
                            let columns = [];
                            let rows = [];
                            let skipped = {
                                total: [],
                                emptyEmail: [],
                                duplicate: [],
                                regex: [],
                                domainFailed: [],
                                risky: [],
                                unknown: [],
                                undeliverable: [],
                                deliverable: []
                            };

                            let startRow = parseInt(this.state.startRow)
                            let endRow = parseInt(this.state.endRow)

                            // let data = [results.data[0]]
                            let datax = results.data.slice(startRow - 1, endRow)
                            datax = _.uniqBy(datax, 1)
                            datax.unshift(results.data[0])

                            console.log(results.data.length)
                            console.log(datax.length)

                            console.log(datax)
        
                            datax.map((data, index) => {
                                if (index === 0) {
                                    columns = data.map(col => {
                                        col = col.toLowerCase()
                                        if(col.includes('name')) {
                                            return 'name'
                                        } else {
                                            return col
                                        }
                                    })
                                    if(!columns.includes('name') || !columns.includes('email') || !columns.includes('position') || !columns.includes('company')) {
                                        throw 'error: columns name, email, position, company may be missing from uploaded CSV'
                                    }
                                } else {
                                    let row = {};
                                    columns.map((column, index) => {
                                        row[column] = data[index]
                                    })
                                    // console.log('row', row)
                                    if(row.email) {
                                        if(row.email.length > 0) {
                                            // regex check
                                            let pass = this.handleRegexCheck(row.email)
                                            if(pass) {
                                                // console.log('passed regex')
                                                rows.push(row)
                                            } else {
                                                skipped.total.push(row)
                                                skipped.regex.push(row)
                                            }
                                        } else {
                                            skipped.total.push(row)
                                            skipped.emptyEmail.push(row)
                                        }
                                    } else {
                                        skipped.total.push(row)
                                        skipped.emptyEmail.push(row)
                                    }
                                }
                            })
        
                            let auth = await Auth.currentAuthenticatedUser()
                            // domain check
                            fetch(`${process.env.REACT_APP_BASE_API_URL}/emails`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    action: "verifyEmails",
                                    newRows: rows
                                }),
                                headers: {
                                    'x-api-key': process.env.REACT_APP_API_KEY,
                                    'Authorization': auth.signInUserSession.idToken.jwtToken
                                }
                            })
                            .then(response => response.json())
                            .then(data => {
                                // console.log('data', data)
                                if(data.length > 0) {
                                    let postDomainCheckRows = data.filter(a => {
                                        if(a.mx) {
                                            if(a.kickbox) {
                                                skipped.deliverable.push(a);
                                                return a;
                                            } else {
                                                if(a.kickboxResult) {
                                                    if(a.kickboxResult.result === 'risky') {
                                                        skipped.risky.push(a)
                                                        return a;
                                                    } else if (a.kickboxResult.result === 'unknown') {
                                                        skipped.unknown.push(a)
                                                    } else if (a.kickboxResult.result === 'undeliverable') {
                                                        skipped.undeliverable.push(a)
                                                    }
                                                    skipped.total.push(a)
                                                } else {
                                                    skipped.total.push(a)
                                                }
                                            }
                                        } else {
                                            skipped.domainFailed.push(a)
                                            skipped.total.push(a)
                                        }
                                    })
                                    console.log('postDomainCheckRows', postDomainCheckRows.length)
                                    console.log('data', data.length)
                                    let newRows = []
                                    let x = _.unionWith(this.props.dbData.rows, postDomainCheckRows, (a, b) => a.email === b.email)
                                    skipped.duplicate = postDomainCheckRows.filter(a => this.props.dbData.rows.some(b => a.email === b.email))
                                    skipped.total = [...skipped.total, ...skipped.duplicate]

                                    let uploadGroupUUID = uuidv4().slice(0,8)
            
                                    let deduplicatedRows = x.map(f => {
                                        if("stage" in f) {
                                            // stage found means existing record so keep the existing
                                            return f;
                                        } else {
                                            let newRecord = {
                                                stage: this.props.stage,
                                                name: f.name,
                                                company: f.company,
                                                position: f.position,
                                                email: f.email,
                                                category: 'generic',
                                                complete: false,
                                                source: this.props.source,
                                                uuid: uuidv4(),
                                                stages: [{stage: 'created', timestamp: new Date().toISOString()}],
                                                linkedin_url: f.linkedin_url,
                                                employee_size: f.employee_size,
                                                created_at: new Date().toISOString(),
                                                upload_group_id: uploadGroupUUID,
                                                kickbox: f.kickboxResult
                                            }
                                            newRows.push(newRecord)
                                            return newRecord
                                        }
                                    })

                                    // console.log({
                                    //     columns, rows: deduplicatedRows, newRows, skipped 
                                    // })
            
                                    this.props.handleFileUpload({ columns, rows: deduplicatedRows, newRows, skipped })
                                }
                            })
                        }
                    }
                })
            }
        } catch (error) {
            console.log('error', error)
        }
    };

    render = () => {
        return (
            <Form.Group controlId="formFile" className="mb-3" style={{marginTop: "20px"}}>
                <InputGroup className="mb-2">
                    <InputGroup.Text>Start Row (2+)</InputGroup.Text>
                    <Form.Control type="text" disabled={this.props.data.rows} value={this.state.startRow} minLength="1" onChange={(e) => this.setState({startRow: e.target.value})} />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Text>End Row (2+)</InputGroup.Text>
                    <Form.Control type="text" disabled={this.props.data.rows} value={this.state.endRow} minLength="1" onChange={(e) => this.setState({endRow: e.target.value})} />
                </InputGroup>
                {
                    this.state.startRow !== '' && this.state.endRow !== '' ?
                    <>
                        <Form.Label>File</Form.Label>
                        <Form.Control accept=".csv" disabled={this.props.data.rows} type="file" onClick={this.props.clearData} onChange={(e) => this.handleFormattingUpload(e)} />
                    </>
                    : <></>
                }
            </Form.Group>
        )
    }
}

export default UploadFile;