import React, { Component } from "react";
import { Modal, Button, Table, Form } from 'react-bootstrap';
import UploadModal from "./UploadModal";
import ViewRowModal from "./ViewRowModal";
import TablePagination from "./TablePagination";

class TableView extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState = () => ({
        checked: [],
        showConfirmModal: false,
        selectAllChecked: false,
        stageSelected: 'none',
        categorySelected: 'none',
        positionSelected: 'none',
        filteredRows: [],
        filters: [],
        showViewRowModal: false,
        viewRow: {},
        startRow: 0,
        endRow: 25
    });

    componentDidMount = async () => {
       await this.filterTable({e: {target: {name: 'stage', value: 'none'}}, startRow: this.state.startRow, endRow: this.state.endRow})
    }

    componentDidUpdate = async prevProps => {
        if(this.props.dbData.rows !== prevProps.dbData.rows) {
            await this.filterTable({e: {target: {name: 'stage', value: 'none'}}, startRow: this.state.startRow, endRow: this.state.endRow})
        }
    }

    handleUploadingCSV = async (data, source, stage) => {
        await this.props.dbDataChange({
            action: 'newRows',
            item: {
                newRows: data.newRows
            }
        })

        this.setState({
            showConfirmModal: false
        })
    }

    filterTable = async ({e, startRow, endRow}) => {
        if (e.target.name === 'stage') this.setState({ stageSelected: e.target.value })
        // if(e.target.name === 'position') this.setState({positionSelected: e.target.value})
        // if(e.target.name === 'category') this.setState({categorySelected: e.target.value})

        let updatedFilters = []
        if (this.state.filters.length <= 0) {
            if (e.target.value !== 'none') {
                updatedFilters.push({
                    name: e.target.name,
                    value: e.target.value
                })
            }
        } else {
            // multiple filters
            this.state.filters.map((filter, index) => {
                if (filter.name !== e.target.name) {
                    updatedFilters.push({
                        name: e.target.name,
                        value: e.target.value
                    })
                } else {
                    if (filter.value !== e.target.value) {
                        updatedFilters[index] = {
                            name: e.target.name,
                            value: e.target.value
                        }
                    }
                }
            })
        }
        let updatedRows = []
        if (updatedFilters.length > 0) {
            // already one filter in play so filter the filter
            this.props.dbData.rows.map(row => {
                let rowValue = row[e.target.name].toLowerCase()
                let targetValue = e.target.value.toLowerCase()
                if (e.target.value === 'none') {
                    if (row.stage !== "not_exist") {
                        updatedRows.push(row)
                    }
                    return;
                } else {
                    updatedFilters.map(filter => {
                        let filterName = filter.name
                        let filterValue = filter.value.toLowerCase()
                        if (e.target.name === filterName) {
                            if (rowValue.includes(filterValue) && rowValue.includes(targetValue)) {
                                updatedRows.push(row)
                                return;
                            }
                        }
                    })
                }
            })
        } else {
            this.props.dbData.rows.map(row => {
                let rowValue = row[e.target.name].toLowerCase()
                let targetValue = e.target.value.toLowerCase()
                if (e.target.value === 'none') {
                    updatedRows.push(row)
                    return;
                }
                if (rowValue === targetValue) {
                    updatedRows.push(row)
                    return;
                }
            })
        }

        if(updatedRows[0].TimeToLive) {
            updatedRows = updatedRows.sort((a, b) => new Date(a.TimeToLive) - new Date(b.TimeToLive)).slice(startRow, endRow)
        } else {
            updatedRows = updatedRows.sort((r1, r2) => new Date(r1.stages[0].timestamp) - new Date(r2.stages[0].timestamp)).reverse().slice(startRow, endRow)
        }

        this.setState({
            filteredRows: updatedRows,
            filters: updatedFilters
        })
    }

    handleConfirmModalClose = () => this.setState({ showConfirmModal: false, showViewRowModal: false })
    showConfirmModal = () => this.setState({ showConfirmModal: true })
    showViewRowModal = rowEmail => {
        this.props.dbData.rows.some(row => {
            if (rowEmail === row.email) {
                this.setState({
                    showViewRowModal: true,
                    viewRow: row
                })
                return;
            }
        })
    }

    handlePageChoice = async ({startRow, endRow}) => {
        await this.filterTable({e: {target: {name: 'stage', value: 'none'}}, startRow, endRow})
        this.setState({
            startRow, endRow
        })
    }

    render = () => {
        return (
            <div>
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-2">
                        <h4>Count: {this.props.dbData.rows.length}</h4>
                    </div>
                    <div className="col-md-8"></div>
                    <div className="col-md-2" style={{ textAlign: "center" }}>
                        <Button variant="success" onClick={this.showConfirmModal}>Upload CSV</Button>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col-md-3">
                        <Form.Label>Stage</Form.Label>
                        <Form.Select name="stage" value={this.state.stageSelected} onChange={(e) => this.filterTable({e, startRow: 0, endRow: 25})}>
                            <option value="none">None</option>
                            <option value="initial">Initial</option>
                            <option value="1st_follow_up">1st Follow Up</option>
                            <option value="2nd_follow_up">2nd Follow Up</option>
                            <option value="3rd_follow_up">3rd Follow Up</option>
                            <option value="responded">Responded</option>
                            <option value="flag">Flag</option>
                            <option value="not_exist">Not Exist</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                </div>
                <UploadModal show={this.state.showConfirmModal} dbDataChange={this.props.dbDataChange} dbData={this.props.dbData} handleUploadingCSV={this.handleUploadingCSV} hide={this.handleConfirmModalClose} />
                <ViewRowModal
                    show={this.state.showViewRowModal}
                    dbDataChange={this.props.dbDataChange}
                    dbData={this.props.dbData}
                    row={this.state.viewRow}
                    hide={this.handleConfirmModalClose}
                />
                <Table style={{ textAlign: "left", marginTop: "30px" }}>
                    <thead>
                        <tr>
                            {
                                this.props.columns.map((name, index) => {
                                    if (name === 'source') return
                                    return <th key={`column-${index}`}>{name}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.filteredRows.map((row, index) => {
                                if(index >= this.state.startRow && index < this.state.endRow) {
                                    return (
                                        <tr key={`row-${index}`}>
                                            <td>{row.stages ? `${new Date(row.stages[0].timestamp).toISOString().split('T')[0]}` : "N/A"}</td>
                                            <td>{row.stage}</td>
                                            <td>{row.name}</td>
                                            <td>{row.company.slice(0, 40)}</td>
                                            <td>{row.position.slice(0, 40)}</td>
                                            <td>{row.email}</td>
                                            <td>{row.category}</td>
                                            <td>
                                                <Button style={{ marginRight: "10px" }} variant="primary" name={row.email} onClick={(e) => this.showViewRowModal(e.target.name)}>View</Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </Table>
                {
                    this.props.dbData.rows.length > 0 ?
                    <TablePagination allRowsLength={this.props.dbData.rows.length} rows={this.state.filteredRows} handlePageChoice={this.handlePageChoice} />
                    : <></>
                }
            </div>
        )
    }
}

export default TableView;