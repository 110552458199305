import { Component } from "react";
import { Table, Form, Button } from 'react-bootstrap';
import ViewRowModal from "./ViewRowModal";
import TablePagination from "./TablePagination";

class Queue extends Component {
    state = {
        filters: [],
        filteredRows: [],
        timeSelected: 'none',
        showViewRowModal: false,
        viewRow: {},
        startRow: 0,
        endRow: 25
    }

    componentDidMount = async () => {
        await this.filterTable({e: {target: {name: 'time', value: 'none'}}, startRow: this.state.startRow, endRow: this.state.endRow})
    }

    componentDidUpdate = async prevProps => {
        if(this.props.dbData.ttl !== prevProps.dbData.ttl) {
            await this.filterTable({e: {target: {name: 'time', value: 'none'}}, startRow: this.state.startRow, endRow: this.state.endRow})
        }
    }

    filterTable = async ({e, startRow, endRow}) => {
        if(e.target.name === 'time') this.setState({timeSelected: e.target.value})

        let SECONDS_IN_AN_HOUR = 60 * 60;
        let secondsSinceEpoch = Math.round(Date.now() / 1000);

        let filterName = this.state.timeSelected
        let filteredRows = []
        if(filterName === 'none') {
            filteredRows = this.props.dbData.ttl;
        } else if(filterName === '1hr') {
            filteredRows = this.props.dbData.ttl.filter(row => row['TimeToLive'] < secondsSinceEpoch + 1 * SECONDS_IN_AN_HOUR)
        } else if (filterName === '6hr') {
            filteredRows = this.props.dbData.ttl.filter(row => row['TimeToLive'] < secondsSinceEpoch + 6 * SECONDS_IN_AN_HOUR)
        } else if (filterName === '24hr') {
            filteredRows = this.props.dbData.ttl.filter(row => row['TimeToLive'] < secondsSinceEpoch + 24 * SECONDS_IN_AN_HOUR)
        } else if (filterName === '7day') {
            filteredRows = this.props.dbData.ttl.filter(row => row['TimeToLive'] < secondsSinceEpoch + 168 * SECONDS_IN_AN_HOUR)
        } else if (filterName === '30day') {
            filteredRows = this.props.dbData.ttl.filter(row => row['TimeToLive'] < secondsSinceEpoch + 720 * SECONDS_IN_AN_HOUR)
        }

        this.setState({
            filteredRows: filteredRows.sort((a, b) => new Date(a.TimeToLive) - new Date(b.TimeToLive)).slice(startRow, endRow)
        })
    }

    handleRefresh = async () => {
        this.setState({
            startRow: 0,
            endRow: 25
        })
        await this.props.getData({action: 'read', pk: 'id', item: {id: 'info@serverlessguru.com'}})
        await this.filterTable({e: {target: {name: 'time', value: 'none'}}, startRow: this.state.startRow, endRow: this.state.endRow})
    }
    handleConfirmModalClose = () => this.setState({ showViewRowModal: false })
    showViewRowModal = rowEmail => {
        this.props.dbData.rows.some(row => {
            if(rowEmail === row.email) {
                this.setState({
                    showViewRowModal: true,
                    viewRow: row
                })
                return;
            }
        })
    }

    handlePageChoice = async ({startRow, endRow}) => {
        await this.filterTable({e: {target: {name: 'time', value: 'none'}}, startRow, endRow})
        this.setState({
            startRow, endRow
        })
    }

    render = () => {
        return (
            <div>
                <div className="row" style={{marginTop: "50px"}}>
                    <div className="col-md-2 d-flex align-items-center">
                        <h4>Count: {this.props.dbData.ttl.length}</h4>
                    </div>
                    <div className="col-md-3 d-flex align-items-center">
                        {/* <Form.Label>Time Filter</Form.Label> */}
                        <Form.Select name="time" value={this.state.timeSelected} onChange={this.filterTable}>
                            <option value="none">None</option>
                            <option value="1hr">1 Hour</option>
                            <option value="6hr">6 Hours</option>
                            <option value="24hr">24 Hours</option>
                            <option value="7day">7 Days</option>
                            <option value="30day">30 Days</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                        <Button onClick={this.handleRefresh}>Refresh</Button>
                    </div>
                    <div className="col-md-5"></div>
                </div>
            <ViewRowModal show={this.state.showViewRowModal} dbDataChange={this.props.dbDataChange} dbData={this.props.dbData} row={this.state.viewRow} hide={this.handleConfirmModalClose} />
            <div className="row">
                <div className="col-md-12">
                    <Table style={{ textAlign: "left", marginTop: "30px" }}>
                        <thead>
                            <tr>
                                {
                                    this.props.columns.map((name, index) => {
                                        if(name === 'source') return
                                        return <th key={`column-${index}`}>{name}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.filteredRows.map((row, index) => {
                                    return (
                                        <tr key={`row-${index}`}>
                                            <td>{row['TimeToLive'] < (Math.round(Date.now() / 1000) + 0 * (60 * 60)) ? "Sending" : "Scheduled"}</td>
                                            <td>{`${new Date(row['TimeToLive']*1000).toLocaleDateString()} ${new Date(row['TimeToLive']*1000).toLocaleTimeString()}`}</td>
                                            <td>{row.stage}</td>
                                            <td>{row.name}</td>
                                            <td>{row.company}</td>
                                            <td>{row.position}</td>
                                            <td>{row.email}</td>
                                            <td>
                                                <Button style={{marginRight: "10px"}} variant="primary" name={row.email} onClick={(e) => this.showViewRowModal(e.target.name)}>View</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    {
                        this.props.dbData.ttl.length > 0 ?
                        <TablePagination allRowsLength={this.props.dbData.ttl.length} rows={this.state.filteredRows} handlePageChoice={this.handlePageChoice} />
                        : <></>
                    }
                </div>
            </div>
            </div>
        )
    }
}

export default Queue;