import { Component } from "react";
import { Form, Button } from "react-bootstrap";

class Preferences extends Component {

    state = {
        name: '',
        initial: "1", // 0.02 = 1 minute
        '1st_follow_up': "120", // 120 / 24 = 5 days
        '2nd_follow_up': "312", // 312 / 24 = 13 days
        '3rd_follow_up': "600" // 600 / 24 = 25 days
    }

    componentDidMount = () => {
        this.setState({
            initial: this.props.dbData.preferences['initial'],
            '1st_follow_up': this.props.dbData.preferences['1st_follow_up'],
            '2nd_follow_up': this.props.dbData.preferences['2nd_follow_up'],
            '3rd_follow_up': this.props.dbData.preferences['3rd_follow_up']
        })
    }

    handleSave = async name => {
        let preferences = this.props.dbData.preferences
        preferences[name] = this.state[name]

        // console.log('preferences', preferences)

        await this.props.dbDataChange({
            action: "updatePreferences",
            item: {
                preferences: preferences,
            }
        })

        this.setState({
            name: ''
        })
    }

    render = () => {
        // console.log(this.state)
        const styles = {
            column: {
                paddingLeft: "20px",
                paddingRight: "20px"
            }
        }
        return (
            <div style={{marginTop: "30px"}}>
                <h3>Delivery Time Defaults</h3>
                <p># of hours e.g. 1 minute = 0.02, 1 day = 24, 5 days = 120, 15 days = 360</p>
                <div className="row">
                    <div className="col-md-3" style={styles.column}>
                        <div className="row">
                            <Form.Label style={{marginTop: "10px"}}>Initial (# of hours)</Form.Label>
                            <Form.Control disabled={this.state.name !== "initial"} name="initial" value={this.state.initial} onChange={(e) => this.setState({[e.target.name]: e.target.value})} type="text"></Form.Control>
                            {
                                this.state.name === 'initial' ?
                                    <>
                                        <Button variant="success" name="initial" onClick={(e) => this.handleSave(e.target.name)}>Save</Button>
                                        <Button variant="danger" name="initial" onClick={() => this.setState({name: ''})}>Cancel</Button>
                                    </>
                                : <Button variant="primary" name="initial" onClick={(e) => this.setState({name: e.target.name})}>Edit</Button>
                            }
                        </div>
                    </div>
                    <div className="col-md-3" style={styles.column}>
                        <div className="row">
                            <Form.Label style={{marginTop: "10px"}}>1st_follow_up (# of hours)</Form.Label>
                            <Form.Control disabled={this.state.name !== "1st_follow_up"} name="1st_follow_up" value={this.state['1st_follow_up']} onChange={(e) => this.setState({[e.target.name]: e.target.value})} type="text"></Form.Control>
                            {
                                this.state.name === '1st_follow_up' ?
                                    <>
                                        <Button variant="success" name="1st_follow_up" onClick={(e) => this.handleSave(e.target.name)}>Save</Button>
                                        <Button variant="danger" name="1st_follow_up" onClick={() => this.setState({name: ''})}>Cancel</Button>
                                    </>
                                : <Button variant="primary" name="1st_follow_up" onClick={(e) => this.setState({name: e.target.name})}>Edit</Button>
                            }
                        </div>
                    </div>
                    <div className="col-md-3" style={styles.column}>
                        <div className="row">
                            <Form.Label style={{marginTop: "10px"}}>2nd_follow_up (# of hours)</Form.Label>
                            <Form.Control disabled={this.state.name !== "2nd_follow_up"} name="2nd_follow_up" value={this.state['2nd_follow_up']} onChange={(e) => this.setState({[e.target.name]: e.target.value})} type="text"></Form.Control>
                            {
                                this.state.name === '2nd_follow_up' ?
                                    <>
                                        <Button variant="success" name="2nd_follow_up" onClick={(e) => this.handleSave(e.target.name)}>Save</Button>
                                        <Button variant="danger" name="2nd_follow_up" onClick={() => this.setState({name: ''})}>Cancel</Button>
                                    </>
                                : <Button variant="primary" name="2nd_follow_up" onClick={(e) => this.setState({name: e.target.name})}>Edit</Button>
                            }
                        </div>
                    </div>
                    <div className="col-md-3" style={styles.column}>
                        <div className="row">
                            <Form.Label style={{marginTop: "10px"}}>3rd_follow_up (# of hours)</Form.Label>
                            <Form.Control disabled={this.state.name !== "3rd_follow_up"} name="3rd_follow_up" value={this.state['3rd_follow_up']} onChange={(e) => this.setState({[e.target.name]: e.target.value})} type="text"></Form.Control>
                            {
                                this.state.name === '3rd_follow_up' ?
                                    <>
                                        <Button variant="success" name="3rd_follow_up" onClick={(e) => this.handleSave(e.target.name)}>Save</Button>
                                        <Button variant="danger" name="3rd_follow_up" onClick={() => this.setState({name: ''})}>Cancel</Button>
                                    </>
                                : <Button variant="primary" name="3rd_follow_up" onClick={(e) => this.setState({name: e.target.name})}>Edit</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preferences