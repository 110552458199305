import { Component } from "react";

import { Table, Button } from 'react-bootstrap';
import TemplateManagerModal from "./TemplateManagerModal";

class TemplateManager extends Component {

    state = {
        showConfirmModal: false,
        templates: [],
        templateIndex: null
    }

    handleConfirmModalClose = () => this.setState({ showConfirmModal: false })
    showConfirmModal = () => this.setState({ showConfirmModal: true })

    handleSubmit = async templates => {
        await this.props.dbDataChange({
            action: "updateTemplates",
            item: {
                template: templates[this.state.templateIndex]
            }
        })

        this.handleConfirmModalClose()
    }

    handleCreate = () => {
        this.setState({
            templateIndex: null
        })
        this.showConfirmModal()
    }

    handleView = templateIndex => {
        this.setState({
            templateIndex
        })
        this.showConfirmModal()
    }

    render = () => {
        return (
            <>
                <div className="row" style={{marginTop: "30px"}}>
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <Button variant="primary" onClick={this.handleCreate}>Create</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Table style={{ textAlign: "left", marginTop: "30px" }}>
                            <thead>
                                <tr>
                                    {
                                        this.props.columns.map((name, index) => {
                                            return <th key={`column-${index}`}>{name}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.dbData.templates.map((row, index) => {
                                        return (
                                            <tr key={`row-${index}`}>
                                                <td>{row.name}</td>
                                                <td>{row.category}</td>
                                                <td>{row.subCategory}</td>
                                                <td>
                                                    <Button style={{marginRight: "10px"}} variant="primary" onClick={() => this.handleView(index)}>View</Button>
                                                    {/* <Button variant={row.complete ? "secondary" : "danger"} disabled={row.complete} onClick={() => this.markComplete(row.email, 'flag')}>Flag</Button> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {
                    this.state.showConfirmModal ?
                        <TemplateManagerModal handleSubmit={this.handleSubmit} hide={this.handleConfirmModalClose} show={this.showConfirmModal} templateIndex={this.state.templateIndex} templates={this.props.dbData.templates} />
                    : <></>
                }
            </>
        )
    }
}

export default TemplateManager