import { Button } from "react-bootstrap";
import { Auth } from 'aws-amplify';
import { Timeline } from '@material-ui/icons';

const TopNav = (props) => {
    return (
        <>
            <div className="row" style={{paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#003300"}}></div>
            <div className="row" style={{paddingTop: "20px", paddingBottom: "20px", backgroundColor: "#1b5e20"}}>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-2" style={{color: "#fff", fontSize: "20px", textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>
                    Sales Graph <Timeline style={{fontSize: "40px", paddingLeft: "3px"}}/>
                </div>
                <div className="col-md-8"></div>
                <div className="col-md-2" style={{textAlign: "center", marginTop: "auto", marginBottom: "auto"}}>
                    <Button variant="warning" onClick={props.signOut}>Logout</Button>
                </div>
            </div>
        </>
    )
}

export default TopNav