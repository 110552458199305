import { Component } from "react";

import TabNav from './TabNav';
import Queue from './Queue';
import TopNav from './TopNav';

import { Auth } from 'aws-amplify';
import TemplateManager from "./TemplateManager";
import Reporting from "./Reporting";
import TableView from "./TableView";
import Preferences from "./Preferences";

// import dns from 'dns'

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        dbData: {},
        isLoading: true,
        tabSelection: 'home'
    });

    componentDidMount = () => {
        this.getData()
    }

    // TODO : add filteredTableRows count

    getData = async () => {
        let auth = await Auth.currentAuthenticatedUser()
        // console.log('auth', auth)

        let request = {
            action: 'read',
            loggedInUserEmail: auth.username
        }

        fetch(`${process.env.REACT_APP_BASE_API_URL}/emails`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': auth.signInUserSession.idToken.jwtToken
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log('data', data)
            // let ttlRows = data.rows.map(row => {
            //     row.TimeToLive = 1647274998
            //     return row
            // })
            // data.rows = [
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows,
            //     ...data.rows, ...data.rows, ...data.rows
            // ]
            // data.ttl = [
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            //     ...ttlRows, ...ttlRows, ...ttlRows,
            // ]
            this.setState({
                dbData: data,
                isLoading: false
            })
        })
    }

    dbDataChange = async request => {
        // console.log('request', request)
        // update db
        if (request.action) {
            // console.log('updating list in backend')
            let auth = await Auth.currentAuthenticatedUser()
            request.loggedInUserEmail = auth.username
            
            fetch(`${process.env.REACT_APP_BASE_API_URL}/emails`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Authorization': auth.signInUserSession.idToken.jwtToken
                }
            })
            .then(response => response.json())
            .then(data => {
                // console.log('data', data)
                this.setState({
                    dbData: data
                })
            })
        }
    }

    handleTabSelection = selection => this.setState({ tabSelection: selection })

    render = () => {
        // console.log(this.state)
        if (this.state.isLoading) {
            return <h1>Loading...</h1>
        }
        return (
            <div className="container-fluid">
                <TopNav signOut={this.props.handleLogout} />
                <TabNav handleTabSelection={this.handleTabSelection} />
                {
                    this.state.tabSelection === 'home' ?
                        <TableView
                            dbDataChange={this.dbDataChange}
                            dbData={this.state.dbData}
                            columns={[
                                "Created",
                                "Stage",
                                "Name",
                                "Company",
                                "Position",
                                "Email",
                                "Category",
                                "Actions"
                            ]}
                            categories={[
                                'generic',
                                'hotels',
                                'airlines'
                            ]}
                        />
                    : this.state.tabSelection === 'queue' ?
                        <Queue
                            dbData={this.state.dbData}
                            getData={this.getData}
                            dbDataChange={this.dbDataChange}
                            columns={[
                                "Status",
                                "Scheduled Date",
                                "Stage",
                                "Name",
                                "Company",
                                "Position",
                                "Email",
                                "Actions"
                            ]}
                        />
                    : this.state.tabSelection === 'reporting' ?
                        <Reporting
                            dbData={this.state.dbData}
                            getData={this.getData}
                        />
                    : this.state.tabSelection === 'templateManager' ?
                        <TemplateManager
                            dbData={this.state.dbData}
                            dbDataChange={this.dbDataChange}
                            getData={this.getData}
                            columns={[
                                'Name',
                                'Category',
                                'Sub Category',
                                'Actions'
                            ]}
                        />
                    : this.state.tabSelection === 'preferences' ?
                        <Preferences
                            dbData={this.state.dbData}
                            getData={this.getData}
                            dbDataChange={this.dbDataChange}
                        />
                    : <></>
                }
            </div>
        )
    }
}

export default Dashboard;