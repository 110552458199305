export default {
  Auth: {
    // identityPoolId: 'us-west-2:210a9488-f966-428a-903d-f7b744741e19',
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  region: process.env.REACT_APP_COGNITO_REGION,
  authenticationType: "AWS_IAM",
  apiKey: process.env.REACT_APP_API_KEY,
  disableOffline: true
}