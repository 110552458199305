import React, { Component } from "react";
import { Pagination } from 'react-bootstrap';

class TablePagination extends Component {

    state = {
        pageClicked: 1
    }

    componentDidMount = () => {
        let numOfPages = Math.ceil(this.props.allRowsLength / 25)
        
        Array.from(Array(this.props.allRowsLength).keys()).map((i, index) => {
            if(i > 25 && i % 25 === 0) {
                return <Pagination.Item onClick={() => this.handleClick({ pageClicked: Math.round(i / 25) })} active={this.state.pageClicked === Math.round(i / 25)}>{Math.round(i / 25)}</Pagination.Item>
            }
        })
    }

    handleClick = ({pageClicked}) => {
        let startRow = 0
        let endRow = 25
        if (pageClicked !== 1) {
            startRow = ((pageClicked - 1) * 25) + 1
            endRow = pageClicked * 25
        }

        this.props.handlePageChoice({startRow, endRow})

        this.setState({
            pageClicked
        })
    }

    render = () => {
        return (
            <Pagination>
                <Pagination.First disabled={this.state.pageClicked === 1} onClick={() => this.handleClick({ pageClicked: 1 })} />
                <Pagination.Prev disabled={this.state.pageClicked === 1} onClick={() => this.handleClick({ pageClicked: this.props.allRowsLength > 25 && this.state.pageClicked !== 1 ? this.state.pageClicked - 1 : 1 })} />
                <Pagination.Item active={this.state.pageClicked === 1} onClick={() => this.handleClick({ pageClicked: 1 })}>{1}</Pagination.Item>
                {
                    this.props.allRowsLength > 25 ?
                    Array.from(Array(Math.ceil(this.props.allRowsLength / 25)).keys()).map(i => {
                        i = i + 1
                        if(i !== 1) return <Pagination.Item onClick={() => this.handleClick({ pageClicked: i})} active={this.state.pageClicked === i}>{i}</Pagination.Item>
                    })
                    : <></>
                }
                <Pagination.Next disabled={this.state.pageClicked === Math.ceil(this.props.allRowsLength / 25)} onClick={() => this.handleClick({ pageClicked: this.props.allRowsLength > 25 ? this.state.pageClicked + 1 : 1 })} />
                <Pagination.Last disabled={this.state.pageClicked === Math.ceil(this.props.allRowsLength / 25)} onClick={() => this.handleClick({ pageClicked: Math.ceil(this.props.allRowsLength / 25)})} />
            </Pagination>
        )
    }
}

export default TablePagination