import { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';

class TemplateManagerModal extends Component {
    state = {
        edit: false,
        name: '',
        category: 'generic',
        subCategory: 'none',
        textMessage: '',
        subject: '',
        from: 'toberny.mason@serverlessguru.com',
        cc: [],
        bcc: ['6392588@bcc.hubspot.com']
    };

    buildHTMLMessage = message => {
        let ps1 = message.split(`\n\n`)

        let htmlMessage = '<html><body>'
        ps1.map(p => {
            if(p.includes('Serverless Guru')) {
                p = p.replace('Serverless Guru', '<a href="https://serverlessguru.com" target="_blank">Serverless Guru</a>')
            }
            if(p.includes('AirCanada')) {
                p = p.replace('AirCanada', '<a href="https://aircanada.com" target="_blank">AirCanada</a>')
            }
            if(p.includes('Hyatt')) {
                p = p.replace('Hyatt', '<a href="https://hyatt.com" target="_blank">Hyatt</a>')
            }
            if(p.includes('Lottery')) {
                p = p.replace('Lottery', '<a href="https://lottery.com" target="_blank">Lottery</a>')
            }
            if(p.includes('Calendly')) {
                p = p.replace('Calendly', '<a href="https://calendly.com/serverless-guru/serverless-guru-introduction-call" target="_blank">Calendly</a>')
            }
            
            if(p.includes('\n')) {
                p.split(`\n`).map(x => {
                    htmlMessage += `<p style="margin:0;">${x}</p>`
                })
            } else {
                htmlMessage += `<p>${p}</p>`   
            }
        })
        htmlMessage += '</body></html>'
        return htmlMessage;
    }

    handleSubmit = () => {
        let templates = this.props.templates
        let row = {
            name: this.state.name,
            category: this.state.category,
            subCategory: this.state.subCategory,
            subject: this.state.subject,
            textMessage: this.state.textMessage,
            htmlMessage: this.buildHTMLMessage(this.state.textMessage),
            cc: this.state.cc,
            bcc: this.state.bcc,
            from: this.state.from
        }
        if(this.props.templateIndex !== null) {
            templates[this.props.templateIndex] = row
        } else {
            if(templates.length > 0) {
                templates.push(row)
            } else {
                templates = [row]
            }
        }
        this.props.handleSubmit(templates)
    }

    componentDidMount = () => {
        if(this.props.templateIndex !== null) {
            let template = this.props.templates[this.props.templateIndex]
            this.setState({
                name: template.name,
                category: template.category,
                subCategory: template.subCategory,
                textMessage: template.textMessage,
                subject: template.subject,
                htmlMessage: template.htmlMessage,
                cc: template.cc,
                bcc: template.bcc,
                from: template.from
            })
        }
    }

    render = () => {
        // console.log(this.state)
        return (
            <Modal show={this.props.show} onHide={this.props.hide}>
                <Modal.Header closeButton>
                    <Modal.Title>Template Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control type="text" disabled={this.state.name === 'initial' || this.state.name === '1st_follow_up' || this.state.name === '2nd_follow_up' || this.state.name === '3rd_follow_up' ? true : false} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />

                    <Form.Label style={{marginTop: "10px"}}>from</Form.Label>
                    <Form.Control type="text" disabled={true} value={this.state.from} onChange={(e) => this.setState({ from: e.target.value })} />

                    <Form.Label style={{marginTop: "10px"}}>cc</Form.Label>
                    <Form.Control type="text" disabled={true} value={this.state.cc.length > 0 ? this.state.cc[0] : ""} onChange={(e) => this.setState({ cc: [e.target.value] })} />

                    <Form.Label style={{marginTop: "10px"}}>bcc</Form.Label>
                    <Form.Control type="text" disabled={true} value={this.state.bcc[0]} onChange={(e) => this.setState({ bcc: [e.target.value] })} />

                    <Form.Label style={{marginTop: "10px"}}>Category</Form.Label>
                    <Form.Select value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })}>
                        <option value="generic">Generic</option>
                        <option value="travel">Travel</option>
                        <option value="hospitality">Hospitality</option>
                    </Form.Select>

                    <Form.Label style={{marginTop: "10px"}}>Sub Category</Form.Label>
                    <Form.Select value={this.state.subCategory} onChange={(e) => this.setState({ subCategory: e.target.value })}>
                        <option value="none">None</option>
                        <option value="hotels">Hotels</option>
                        <option value="airlines">Airlines</option>
                    </Form.Select>

                    <Form.Label style={{marginTop: "10px"}}>Subject</Form.Label>
                    <Form.Control type="text" value={this.state.subject} onChange={(e) => this.setState({ subject: e.target.value })} />

                    <Form.Label style={{marginTop: "10px"}}>Text Message</Form.Label>
                    <Form.Control as="textarea" rows={15} value={this.state.textMessage} onChange={(e) => this.setState({ textMessage: e.target.value })} />
                    {/* {this.state.htmlMessage} */}

                    <Form.Label style={{marginTop: "10px"}}>HTML Message</Form.Label>
                    { <div style={{backgroundColor: "#e9ecef", padding: "0.375rem 0.75rem", color: "#212529", border: "1px solid #ced4da", borderRadius: "0.25rem"}} dangerouslySetInnerHTML={{ __html: this.state.htmlMessage }} /> }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" variant="secondary" onClick={this.props.hide}>
                        Close
                    </Button>
                    <Button variant="primary" variant="primary" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default TemplateManagerModal;