import { Component } from "react";
import { Modal, Button, Form, Accordion } from 'react-bootstrap';

class ViewRowModal extends Component {
    state = {
        note: '',
        addNote: false
    };

    markComplete = async stage => {
        let row = this.props.row;
        row.complete = true;
        row.stage = stage;

        await this.props.dbDataChange({
            action: 'updateRow',
            item: {
                row,
                change: stage
            }
        })
        this.props.hide()
        this.setState({
            note: '',
            addNote: false
        })
    }

    saveNote = async () => {
        let row = this.props.row;
        if(row.notes) {
            row.notes.push({
                note: this.state.note,
                timestamp: new Date().toISOString()
            })
        } else {
            row.notes = [{
                note: this.state.note,
                timestamp: new Date().toISOString()
            }]
        }
        await this.props.dbDataChange({
            action: 'updateRow',
            item: {
                row,
                change: 'new_note'
            }
        })
        this.setState({
            note: '',
            addNote: false
        })
    }

    hide = () => {
        this.setState({
            note: '',
            addNote: false
        })
        this.props.hide()
    }

    render = () => {
        // console.log(this.state.data)
        let row = this.props.row;
        let btnVariant = 'primary'
        if(row.stage === 'flag') {
            btnVariant = 'danger'
        } else if (row.stage === 'responded') {
            btnVariant = 'success'
        } else if (row.stage === 'not_exist') {
            btnVariant = 'warning'
        }
        let styles = {
            noteStyle: {
                padding: "10px",
                backgroundColor: "#eee",
                borderRadius: "8px",
                borderColor: "#ccc",
                marginTop: "10px"
            }
        }
        if(this.props.row) {
            // console.log(row.kickbox)
            return (
                <div className="container">
                    <Modal show={this.props.show} onHide={this.hide}>
                        <Modal.Header closeButton>
                            <Modal.Title>{row.email}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul>
                                <li>Name: <span>{row.name}</span></li>
                                <li>Email: {row.email}</li>
                                <li>Company: {row.company}</li>
                                <li>Position: {row.position}</li>
                                <li>Source: {row.source}</li>
                                <li>Stage: <Button variant={btnVariant} disabled={true}>{row.stage}</Button></li>
                                <li>UUID: {row.uuid}</li>
                                <li>LinkedIn: {row.linkedin_url ? <a href={row.linkedin_url} target="_blank">view</a> : "none"}</li>
                                <li>Upload Group UUID: {row.upload_group_id ? row.upload_group_id : "none"}</li>
                                <li>Enhanced
                                    <ul>
                                        <li>Result: {row.kickbox ? row.kickbox.result : "none"}</li>    
                                        <li>Reason: {row.kickbox ? row.kickbox.reason : "none"}</li>
                                        <li>Quality (0-1): {row.kickbox ? row.kickbox.sendex : "none"}</li>
                                    </ul>
                                </li>
                            </ul>
                            {
                                row.stages ?
                                    <>
                                        <h4>Timeline</h4>
                                        <Accordion defaultActiveKey="0">
                                            {
                                                row.stages.map((stage, i) => {
                                                    return (
                                                        <Accordion.Item eventKey={i}>
                                                            <Accordion.Header>Stage: {stage.stage}</Accordion.Header>
                                                            <Accordion.Body>
                                                            <ul>
                                                                <li>Date: {`${new Date(stage.timestamp).toLocaleDateString()} ${new Date(stage.timestamp).toLocaleTimeString()}`}</li>
                                                                {stage.source ? <li>Source: {stage.source}</li> : ""}
                                                            </ul>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </>
                                : <></>
                            }
                            <Form.Group className="mb-3" style={{marginTop: "20px"}}>
                                <Form.Label>Notes</Form.Label>
                                { !this.state.addNote ?
                                <Button style={{marginLeft: "10px"}} onClick={() => this.setState({addNote: true})}>Add</Button>
                                : <></>
                                }
                                { this.state.addNote ?
                                <>
                                    <Form.Control as="textarea" style={{marginTop: "10px"}} rows={2} value={this.state.note} onChange={(e) => this.setState({note: e.target.value})} />
                                    <Button style={{marginTop: "10px", marginRight: "10px"}}variant="danger" onClick={() => this.setState({addNote: false})}>Cancel</Button>
                                    <Button variant="success" style={{marginTop: "10px"}} disabled={this.state.note.length > 0 ? false : true} onClick={this.saveNote}>Save</Button>
                                </>
                                : <></>
                                }
                            </Form.Group>
                            {
                                row.notes ?
                                row.notes.map(note => {
                                    return <>
                                        <p style={styles.noteStyle}>{note.timestamp}: {note.note}</p>
                                    </>
                                })
                                : <p>No notes</p>
                            }
                            <Button style={{marginRight: "10px"}} variant={row.complete ? "secondary" : "success"} disabled={row.complete} onClick={() => this.markComplete('responded')}>Responded</Button>
                            <Button variant={row.complete ? "secondary" : "danger"} disabled={row.complete} onClick={() => this.markComplete('flag')}>Flag</Button>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" variant="secondary" onClick={this.hide}>Close</Button>
                            {/* <Button variant="primary" disabled={this.state.data.rows ? false : true} variant="primary" onClick={async () => await this.props.handleUploadingCSV(this.state.data, this.state.source, this.state.stage)}>
                                Upload
                            </Button> */}
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        }
    }
}

export default ViewRowModal;