import { Component } from "react";
import { Card } from 'react-bootstrap';

class Reporting extends Component {
    state = {
        initial: [],
        flagged: [],
        responded: [],
        not_exist: [],
        follow_up_1: [],
        follow_up_2: [],
        follow_up_3: [],
        follow_up_4: []
    }
    
    componentDidMount = () => {
        let flagged = []
        let responded = []
        let initial = []
        let not_exist = []
        let follow_up_1 = []
        let follow_up_2 = []
        let follow_up_3 = []
        let follow_up_4 = []
        this.props.dbData.rows.map(row => {
            if (row.stage === 'flag') {
                flagged.push(row)
            } else if (row.stage === 'responded') {
                responded.push(row)
            } else if (row.stage === '1st_follow_up') {
                follow_up_1.push(row)
            } else if (row.stage === '2nd_follow_up') {
                follow_up_2.push(row)
            } else if (row.stage === '3rd_follow_up') {
                follow_up_3.push(row)
            } else if (row.stage === '4th_follow_up') {
                follow_up_4.push(row)
            } else if (row.stage === 'initial') {
                initial.push(row)
            } else if (row.stage === 'not_exist') {
                not_exist.push(row)
            }
        })
        this.setState({
            initial: initial,
            flagged: flagged,
            responded: responded,
            follow_up_1: follow_up_1,
            follow_up_2: follow_up_2,
            follow_up_3: follow_up_3,
            follow_up_4: follow_up_4,
            not_exist: not_exist
        })
    }
    render = () => {
        // console.log(this.props)
        const styles = {
            cardStyle: {
                height: "250px",
                paddingTop: "50px"
            },
            cardText: {
                marginTop: "20px"
            }
        }
        return (
            <>
                <div className="row" style={{marginTop: "30px"}}>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>Initial</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in initial stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.initial.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>1st Follow Up</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in 1st_follow_up stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.follow_up_1.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>2nd Follow Up</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in 2nd_follow_up stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.follow_up_2.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>3rd Follow Up</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in 3rd_follow_up stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.follow_up_3.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row" style={{marginTop: "30px"}}>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>Not Exist</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in not_exist stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.not_exist.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>Responded</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in responded stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.responded.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>Flagged</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in flagged stage</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.state.flagged.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>Total Unique</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of unique emails</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.props.dbData.rows.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row" style={{marginTop: "30px"}}>
                    <div className="col-md-3">
                        <Card style={styles.cardStyle}>
                            <Card.Body>
                                <Card.Title>In Queue</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"># of emails in queue</Card.Subtitle>
                                <Card.Text style={styles.cardText}>
                                    <h1>{this.props.dbData.ttl.length}</h1>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>
        )
    }
}

export default Reporting